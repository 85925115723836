import { common } from './common';
import { mix, rgba } from 'polished';

const colors = {
  white: '#ffffff',
  black: '#000000',
  greenPalette: {
    90: '#181B1A',
    85: '#293A38',
    80: '#375551',
    70: '#326760',
    65: '#41847B',
    60: '#76BCB3',
    50: '#9ECBC5',
    10: '#DDEEEC',
  },
  red: '#FF536E',
  green: '#35B374',
  orange: '#e08a38',
  grey: '#C6D2CE',
};

export const dark = {
  ...common,
  colors,
  global: {
    background: colors.greenPalette[90],
    text: colors.white,
  },
  palette: {
    button: {
      /* ['background', 'background:hover', 'color', 'color:hover'] */
      default: [
        'transparent',
        'transparent',
        colors.white,
        colors.greenPalette[60],
      ],
      primary: [colors.greenPalette[65], colors.greenPalette[60], colors.white],
      secondary: [
        colors.greenPalette[80],
        colors.greenPalette[70],
        colors.white,
      ],
      tertiary: [
        colors.greenPalette[85],
        colors.greenPalette[80],
        colors.white,
      ],
      danger: [rgba(colors.red, 0.3), rgba(colors.red, 0.5), colors.red],
      disabled: [
        colors.greenPalette[80],
        colors.greenPalette[80],
        rgba(colors.white, 0.4),
      ],
      active: [
        colors.greenPalette[60],
        colors.greenPalette[60],
        colors.greenPalette[90],
      ],
      invalid: [colors.red, mix(0.1, colors.black, colors.red), colors.white],
      faded: [rgba(colors.white, 0.1), rgba(colors.white, 0.2), colors.white],
    },
    link: {
      /* ['color:default', 'color:hover', 'color.active', 'underline'] */
      default: [
        colors.white,
        colors.greenPalette[60],
        colors.greenPalette[60],
        colors.greenPalette[70],
      ],
      primary: [colors.greenPalette[60], colors.white, colors.white],
      secondary: [
        colors.greenPalette[70],
        colors.greenPalette[65],
        colors.greenPalette[65],
      ],
    },
    card: {
      /* [background, border:hover, background:active, box-shadow] */
      default: [
        colors.greenPalette[85],
        colors.greenPalette[60],
        colors.greenPalette[70],
        '0 4px 8px rgba(0, 0, 0, 0.3), 0 2px 4px rgba(0, 0, 0, 0.15)',
      ],
      primary: [
        colors.greenPalette[80],
        colors.greenPalette[60],
        colors.greenPalette[80],
        '0 4px 8px rgba(0, 0, 0, 0.3), 0 2px 4px rgba(0, 0, 0, 0.15)',
      ],
      secondary: [
        colors.greenPalette[90],
        colors.greenPalette[90],
        colors.greenPalette[90],
        '0 4px 8px rgba(0, 0, 0, 0.3), 0 2px 4px rgba(0, 0, 0, 0.15)',
      ],
    },
    article: {
      /* [border] */
      default: [colors.greenPalette[85]],
      primary: [colors.greenPalette[80]],
    },
    heading: {
      /* [color] */
      default: [colors.greenPalette[10]],
      primary: [colors.greenPalette[60]],
    },
    color: {
      /* color */
      default: [colors.greenPalette[10]],
      primary: [colors.greenPalette[65]],
      secondary: [colors.greenPalette[70]],
      tertiary: [colors.greenPalette[85]],
      red: [colors.red],
      green: [colors.green],
      orange: [colors.orange],
      grey: [colors.grey],
      white: [colors.white],
      active: [colors.greenPalette[60]],
      muted: [rgba(colors.white, 0.7)],
    },
    background: {
      /* [background, color]*/
      default: [],
      primary: [colors.greenPalette[65], colors.white],
      secondary: [colors.greenPalette[80], colors.white],
      tertiary: [colors.greenPalette[85], colors.white],
      red: [colors.red, colors.greenPalette[90]],
      green: [colors.green, colors.greenPalette[90]],
      orange: [colors.orange, colors.greenPalette[90]],
      grey: [colors.grey, colors.greenPalette[90]],
      white: [colors.white, colors.greenPalette[90]],
    },
    label: {
      default: [rgba(colors.white, 0.7)],
      inverse: [rgba(colors.greenPalette[90], 0.7)],
      primary: [colors.greenPalette[10]],
    },
    input: {
      /* [background, border, border: focus] */
      default: [
        rgba(colors.white, 0.1),
        'transparent',
        colors.greenPalette[70],
      ],
      invalid: [
        rgba(colors.white, 0.1),
        rgba(colors.red, 0.5),
        colors.greenPalette[70],
      ],
      autofill: [mix(0.1, colors.white, colors.greenPalette[85])],
    },
    loader: {
      /* [background, border-top, border] */
      default: [
        rgba(colors.greenPalette[90], 0.5),
        colors.white,
        rgba(colors.white, 0.2),
      ],
    },
    tabs: {
      /* [background, color, background:hover, color:hover, background:active, color:active] */
      default: [
        rgba(colors.white, 0.1),
        colors.white,
        colors.greenPalette[80],
        colors.greenPalette[50],
        colors.greenPalette[65],
        colors.white,
      ],
      primary: [
        colors.greenPalette[85],
        colors.greenPalette[50],
        colors.greenPalette[80],
        colors.greenPalette[50],
        colors.greenPalette[65],
        colors.white,
      ],
    },
    hr: {
      default: [rgba(colors.white, 0.1)],
      primary: [colors.greenPalette[70]],
    },
    select: {
      default: [
        rgba(colors.white, 0.1), // control:background
        'transparent', // control:border
        colors.greenPalette[80], // menu:background
        colors.greenPalette[70], // control:background:open
        colors.white, // placeholder:color
        colors.white, // value:color
        colors.greenPalette[60], // indicator:hover
        colors.greenPalette[70], // option:focus
        colors.greenPalette[60], // option:selected
        colors.white, // indicator
        colors.greenPalette[65], // multi-value:background
        colors.white, // multi-value:color
        colors.greenPalette[60], // multi-value-indicator:background
        colors.white, // multi-value-indicator:color,
        colors.white, //option:color
      ],
      primary: [
        colors.greenPalette[85], // control:background
        'transparent', // control:border
        colors.greenPalette[85], // menu:background
        colors.greenPalette[80], // control:background:open
        colors.white, // placeholder:color
        colors.white, // value:color
        colors.greenPalette[60], // indicator:hover
        colors.greenPalette[80], // option:focus
        colors.greenPalette[65], // option:selected
        colors.white, // indicator
        colors.greenPalette[65], // multi-value:background
        colors.white, // multi-value:color
        colors.greenPalette[60], // multi-value-indicator:background
        colors.white, // multi-value-indicator:color,
        colors.white, //option:color
      ],
      search: [
        rgba(colors.white, 0.1), // control:background
        'transparent', // control:border
        colors.greenPalette[85], // menu:background
        colors.greenPalette[70], // control:background:open
        rgba(colors.white, 0.7), // placeholder:color
        colors.white, // value:color
        colors.greenPalette[60], // indicator:hover
        colors.greenPalette[70], // option:focus
        colors.greenPalette[60], // option:selected
        colors.white, // indicator
        colors.greenPalette[65], // multi-value:background
        colors.white, // multi-value:color
        colors.greenPalette[60], // multi-value-indicator:background
        colors.white, // multi-value-indicator:color,
        colors.white, //option:color
      ],
      invalid: [rgba(colors.white, 0.1), rgba(colors.red, 0.5)],
    },
    checkbox: {
      default: [
        'transparent',
        colors.greenPalette[65], // background
        colors.greenPalette[70], // border
        colors.greenPalette[65], // background:hover
      ],
      checked: [
        colors.greenPalette[65], // background
        colors.greenPalette[65], // border
        colors.greenPalette[65], // background:hover
        colors.greenPalette[65], //border:hover
      ],
    },
    pill: {
      /* background, border, background:hover, border:hover */
      default: [
        'transparent',
        colors.greenPalette[65],
        colors.greenPalette[85],
        colors.greenPalette[65],
        colors.white,
      ],
      active: [
        colors.greenPalette[65],
        colors.greenPalette[65],
        colors.greenPalette[65],
        colors.greenPalette[65],
        colors.white,
      ],
    },
    modal: {
      /* bg:background */
      default: [rgba(colors.greenPalette[90], 0.7)],
    },
    file: {
      /* border */
      default: [colors.greenPalette[80]],
    },
    range: {
      /* rail:background, track:background, handle:background */
      default: [
        colors.greenPalette[80],
        colors.greenPalette[60],
        colors.greenPalette[65],
      ],
    },
    tooltip: {
      /* border, background, color */
      default: [
        rgba(colors.white, 0.6),
        rgba(colors.white, 0.6),
        colors.greenPalette[90],
      ],
    },
    placeholder: {
      default: [rgba(colors.white, 0.1)],
    },
    datepicker: {
      default: [
        colors.greenPalette[80], // root:background
        colors.greenPalette[70], // header:background
        colors.white, // day:color
        colors.greenPalette[70], // day:color:hover
        colors.greenPalette[60], // day:color:selected
        rgba(colors.white, 0.5), // day:color:outside-month
        colors.white, // day:color:selected
        colors.greenPalette[60], // arrow:border
        colors.greenPalette[10], // arrow:border:hover
      ],
    },
    chart: {
      default: [
        rgba(colors.white, 0.1), // line:stroke
        colors.greenPalette[90], // bubble:color
        colors.white, // bubble:background:hover
      ],
    },
    carousel: {
      default: [
        colors.greenPalette[60], // button:color
        colors.greenPalette[10], // button:color:hover
        colors.greenPalette[85], // button:color:disabled
        colors.greenPalette[80], // bullet:color
        colors.greenPalette[60], // bullet:color
      ],
    },
    progress: {
      /* [outer:background, inner:background] */
      primary: [colors.greenPalette[80], colors.greenPalette[65]],
      secondary: [colors.greenPalette[85], colors.greenPalette[65]],
    },
  },
};
