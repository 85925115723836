import React, { createContext, ReactNode, useEffect, useState } from 'react';
import { AxiosError } from 'axios';
import { useHistory, useLocation } from 'react-router-dom';
import { useAsync } from 'react-use';
import { useTranslation } from 'react-i18next';

import { api } from '../../services/api';
import { setAuthorizationHeader } from '../../services/interceptors';
import {
  createDeviceId,
  createTokenCookies,
  getAccessToken,
  getDeviceId,
  removeTokenCookies,
} from '../../utils/auth-cookies';
import { Language, Role } from '../../types/Dict';
import { Client } from '../../types/Client';

interface User {
  id: number;
  email: string;
  clientid: number;
  client: Client;
  role: Role;
  firstname: string;
  lastname: string;
  language: Language;
  lastloggedin: string | null;
  coach_profile_id: number;
  coach_accepted_terms: boolean | null;
}

interface SignInCredentials {
  email: string;
  password: string;
}

interface OtpVerify {
  email: string;
  secret: string;
}

interface SignInResponse {
  accessToken: string | null;
  refreshToken: string | null;
  user: User | null;
  twoFactorAuth: boolean;
}

interface OtpResponse {
  accessToken: string | null;
  refreshToken: string | null;
  user: User | null;
}

interface BrowserLocation {
  pathname: string;
}

interface AuthContextData {
  signIn: (
    credentials: SignInCredentials,
    from: BrowserLocation | null,
  ) => Promise<void | AxiosError>;
  otpVerify: (
    params: OtpVerify,
    from: BrowserLocation | null,
  ) => Promise<void | AxiosError<{ message: string }>>;
  signOut: () => void;
  user: User;
  isAuthenticated: boolean;
  loadingUserData: boolean;
  getUserData: (loading: boolean) => Promise<void>;
}

interface AuthProviderProps {
  children: ReactNode;
}

export const AuthContext = createContext({} as AuthContextData);

export function AuthProvider({ children }: AuthProviderProps) {
  const [user, setUser] = useState<User | null>();
  const [loadingUserData, setLoadingUserData] = useState(true);
  const history = useHistory();
  const { pathname, search } = useLocation();
  const isAuthenticated = Boolean(user);
  const userData = user as User;
  const { i18n } = useTranslation();
  const [accessToken, setAccessToken] = useState<string | null>(null);
  const [deviceId, setDeviceId] = useState<string | null>(null);

  const { loading: accessTokenLoading } = useAsync(async () => {
    const accessToken = await getAccessToken();
    const deviceId = await getDeviceId();

    setAccessToken(accessToken);
    setDeviceId(deviceId);
  });

  async function signIn(
    { email, password }: SignInCredentials,
    from: BrowserLocation | null = null,
  ) {
    try {
      const response = await api.post<SignInResponse>('/auth/signin', {
        email,
        password,
        device_id: deviceId,
      });
      const { accessToken, refreshToken, user, twoFactorAuth } = response.data;

      if (twoFactorAuth) {
        history.push({
          pathname: '/auth/otp/verify',
          state: {
            email,
            password,
            from,
          },
        });
      } else if (user && accessToken && refreshToken) {
        setAccessToken(accessToken);
        await createTokenCookies(accessToken, refreshToken);
        setUser(user);
        await i18n.changeLanguage(user.language.code || 'en');
        // @ts-ignore
        setAuthorizationHeader(api.defaults, accessToken);
        history.push(from || '/');
      }
    } catch (error) {
      const err = error as AxiosError;
      return err;
    }
  }

  async function otpVerify(
    { secret, email }: OtpVerify,
    from: BrowserLocation | null = null,
  ) {
    try {
      const response = await api.post<OtpResponse>(`/auth/2fa`, {
        email,
        secret,
        device_id: deviceId,
      });
      const { accessToken, refreshToken, user } = response.data;

      if (user && accessToken && refreshToken) {
        setAccessToken(accessToken);
        await createTokenCookies(accessToken, refreshToken);
        setUser(user);
        await i18n.changeLanguage(user.language.code || 'en');
        // @ts-ignore
        setAuthorizationHeader(api.defaults, accessToken);
        history.push(from || '/');
      }
    } catch (error) {
      const err = error as AxiosError<{ message: string }>;
      return err;
    }
  }

  function signOut() {
    api.get(`/auth/logout`);
    flashUserData();
  }

  async function flashUserData(newPathname = '/auth/login') {
    if (accessTokenLoading) return;

    await removeTokenCookies();
    setUser(null);
    setLoadingUserData(false);

    if (newPathname !== pathname) {
      history.push({
        pathname,
        search,
      });
    }
  }

  async function getUserData(loading: boolean = true) {
    loading && setLoadingUserData(true);

    try {
      const { data: user } = await api.get<User>('/auth/iam');

      if (user) {
        setUser(user);
        await i18n.changeLanguage(user?.language?.code || 'en');
      }
    } catch (error) {
      await flashUserData();
    }

    setLoadingUserData(false);
  }

  useAsync(async () => {
    if (!deviceId) await createDeviceId();
  });

  useEffect(() => {
    if (!accessToken && !accessTokenLoading) flashUserData(pathname);
  }, [pathname, accessToken, accessTokenLoading]);

  useAsync(async () => {
    const accessToken = await getAccessToken();

    if (accessToken) {
      // @ts-ignore
      setAuthorizationHeader(api.defaults, accessToken);
      await getUserData();
    }
  });

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        user: userData,
        loadingUserData,
        signIn,
        signOut,
        otpVerify,
        getUserData,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}
