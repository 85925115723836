import React, { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import styled from 'styled-components/macro';
import { Link as RouterLink } from 'react-router-dom';

import { Col, Row } from '../../Grid';
import { Img } from '../../Img';
import { NavButton, NavLink } from './NavLink';
import { Icon } from '../../Icon';
import { PrivateBlock } from '../../PrivateBlock';
import { Search } from '../../Search';
import { AuthContext } from '../../../../context/AuthContext';
import { selectThemeKey } from '../../../../styles/theme/slice/selectors';
import { isSystemDark } from '../../../../styles/theme/utils';
import { Label } from '../../Label';

import LogoLight from '../assets/logo-light.png';
import LogoDark from '../assets/logo-dark.png';
import { palette } from '../../../../styles/tools';
import { media } from '../../../../styles/media';

export function DesktopNav() {
  const { t } = useTranslation();
  const { signOut } = useContext(AuthContext);
  const themeKey = useSelector(selectThemeKey);

  const theme = useMemo(() => {
    if (themeKey === 'system') {
      return isSystemDark ? 'dark' : 'light';
    }

    return themeKey;
  }, [themeKey]);

  return (
    <Wrapper>
      <Row gutter={0}>
        <Col xs={12} gutter={2}>
          <RouterLink to="/">
            <Row>
              <Col>
                <Img
                  imgSize={32}
                  src={theme === 'dark' ? LogoLight : LogoDark}
                />
              </Col>
              <Col>
                <NavLogo>
                  coach
                  <br />
                  inside
                </NavLogo>
              </Col>
            </Row>
          </RouterLink>
        </Col>
        <PrivateBlock tags={['!coach']}>
          <Col xs={12} gutter={1.5} gutterY={1.25}>
            <Search />
          </Col>
        </PrivateBlock>
        <Col xs={12} gutter={0} gutterY={1.25}>
          <NavLink to="/home" tags={['!coach']}>
            <Row alignItems="center" gutter={0.5}>
              <Col gutter={0.5}>
                <Icon name="home" />
              </Col>
              <Col gutter={0.5}>{t('navigation.home')}</Col>
            </Row>
          </NavLink>
          <NavLink to="/profile" tags={['coach']}>
            <Row alignItems="center" gutter={0.5}>
              <Col gutter={0.5}>
                <Icon name="person" />
              </Col>
              <Col gutter={0.5}>{t('navigation.myProfile')}</Col>
            </Row>
          </NavLink>
          <NavLink to="/access-management" tags={['coach']}>
            <Row alignItems="center" gutter={0.5} flexWrap="nowrap">
              <Col gutter={0.5}>
                <Icon name="key" />
              </Col>
              <Col gutter={0.5}>{t('navigation.accessManagement')}</Col>
            </Row>
          </NavLink>
        </Col>
        <PrivateBlock tags={['!coach']}>
          <Col xs={12} gutter={0} gutterY={1.5}>
            <Row gutter={0}>
              <Col xs={12} gutter={2}>
                <Label>{t('label.profiles')}</Label>
              </Col>
              <Col xs={12} gutterY={0.25} gutter={0}>
                <NavLink to="/coaches" permissions={['access_coaches']}>
                  <Row alignItems="center" gutter={0.5}>
                    <Col gutter={0.5}>
                      <Icon name="person" />
                    </Col>
                    <Col gutter={0.5}>{t('navigation.coaches')}</Col>
                  </Row>
                </NavLink>
              </Col>
            </Row>
          </Col>
        </PrivateBlock>
        <PrivateBlock tags={['!coach']}>
          <Col xs={12} gutter={0} gutterY={1.5}>
            <Row gutter={0}>
              <Col xs={12} gutter={2}>
                <Label>{t('label.lists')}</Label>
              </Col>
              <Col xs={12} gutter={0} gutterY={0.25}>
                <NavLink to="/collections" permissions={['access_collections']}>
                  <Row alignItems="center" gutter={0.5}>
                    <Col gutter={0.5}>
                      <Icon name="discover" />
                    </Col>
                    <Col gutter={0.5}>{t('navigation.discover')}</Col>
                  </Row>
                </NavLink>
              </Col>
              <Col xs={12} gutter={0} gutterY={0.25}>
                <NavLink to="/shortlists" permissions={['access_shortlists']}>
                  <Row alignItems="center" gutter={0.5}>
                    <Col gutter={0.5}>
                      <Icon name="bookmark" />
                    </Col>
                    <Col gutter={0.5}>{t('navigation.shortlists')}</Col>
                  </Row>
                </NavLink>
              </Col>
            </Row>
          </Col>
        </PrivateBlock>
        <PrivateBlock tags={['!coach']}>
          <Col xs={12} gutter={0} gutterY={1.5}>
            <Row gutter={0}>
              <Col xs={12} gutter={2}>
                <Label>{t('label.tools')}</Label>
              </Col>
              <Col xs={12} gutter={0} gutterY={0.25}>
                <NavLink to="/compare" permissions={['access_coaches']}>
                  <Row alignItems="center" gutter={0.5}>
                    <Col gutter={0.5}>
                      <Icon name="compare" />
                    </Col>
                    <Col gutter={0.5}>{t('navigation.compare')}</Col>
                  </Row>
                </NavLink>
              </Col>
              <Col xs={12} gutter={0} gutterY={0.25}>
                <NavLink to="/player-relation" permissions={['access_coaches']}>
                  <Row alignItems="center" gutter={0.5}>
                    <Col gutter={0.5}>
                      <Icon name="relation-1" />
                    </Col>
                    <Col gutter={0.5}>{t('navigation.relation')}</Col>
                  </Row>
                </NavLink>
              </Col>
            </Row>
          </Col>
        </PrivateBlock>
        <Col xs={12} gutter={0} gutterY={1.5}>
          <Row gutter={0}>
            <Col xs={12} gutter={2}>
              <Label>{t('label.user')}</Label>
            </Col>
            <Col xs={12} gutter={0} gutterY={0.25}>
              <NavLink to="/faq">
                <Row alignItems="center" gutter={0.5}>
                  <Col gutter={0.5}>
                    <Icon name="question" />
                  </Col>
                  <Col gutter={0.5}>{t('navigation.faq')}</Col>
                </Row>
              </NavLink>
            </Col>
            <Col xs={12} gutter={0} gutterY={0.25}>
              <NavLink to="/settings">
                <Row alignItems="center" gutter={0.5}>
                  <Col gutter={0.5}>
                    <Icon name="settings" />
                  </Col>
                  <Col gutter={0.5}>{t('navigation.settings')}</Col>
                </Row>
              </NavLink>
            </Col>
            <Col xs={12} gutter={0} gutterY={0.25}>
              <NavLink to="/admin" tags={['admin']}>
                <Row alignItems="center" gutter={0.5}>
                  <Col gutter={0.5}>
                    <Icon name="key" />
                  </Col>
                  <Col gutter={0.5}>{t('navigation.admin')}</Col>
                </Row>
              </NavLink>
            </Col>
            <Col xs={12} gutter={0}>
              <NavButton onClick={signOut}>
                <Row alignItems="center" gutter={0.5}>
                  <Col gutter={0.5}>
                    <Icon name="log-out" />
                  </Col>
                  <Col gutter={0.5}>{t('navigation.logOut')}</Col>
                </Row>
              </NavButton>
            </Col>
          </Row>
        </Col>
      </Row>
    </Wrapper>
  );
}

const Wrapper = styled.header`
  padding: 1.5rem 0.5rem;
  background: ${palette('card', 'default')};
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  width: 14rem;
  backdrop-filter: none;

  ${media.xl} {
    padding: 2rem 0.5rem;
    width: 15rem;
  }

  @media print {
    display: none;
  }
`;

const NavLogo = styled.div`
  text-transform: uppercase;
  font-size: 13px;
  line-height: 16px;
  letter-spacing: 0.8px;
  font-weight: bold;
`;
