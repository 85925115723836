import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components/macro';
import { rgba } from 'polished';

import { NavLink } from './NavLink';
import { Icon } from '../../Icon';
import { palette, theme, withProp } from '../../../../styles/tools';

export function MobileNav() {
  const { t } = useTranslation();

  return (
    <Wrapper>
      <Nav>
        <NavLink to="/profile" tags={['coach']}>
          <Icon name="person" />
          <br />
          {t('navigation.myProfile')}
        </NavLink>
        <NavLink to="/" tags={['!coach']} exact>
          <Icon name="logo" />
          <br />
          {t('navigation.home')}
        </NavLink>
        <NavLink to="/coaches" permissions={['access_coaches']}>
          <Icon name="person" />
          <br />
          {t('navigation.coaches')}
        </NavLink>
        <NavLink to="/collections" permissions={['access_collections']}>
          <Icon name="discover" />
          <br />
          {t('navigation.discover')}
        </NavLink>
        <NavLink to="/shortlists" permissions={['access_shortlists']}>
          <Icon name="bookmark" />
          <br />
          {t('navigation.shortlists')}
        </NavLink>
        <NavLink to="/search" permissions={['access_coaches']}>
          <Icon name="search" />
          <br />
          {t('navigation.search')}
        </NavLink>
      </Nav>
    </Wrapper>
  );
}

const Wrapper = styled.header`
  padding: 6px 16px var(--safe-area-inset-bottom, 6px);
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 2;
  border-top: 1px solid ${theme('global.background')};
  backdrop-filter: blur(25px);
  background-color: ${withProp(palette('card', 'default'), background =>
    rgba(background, 0.8),
  )};

  @media print {
    display: none;
  }
`;

const Nav = styled.div`
  display: flex;
  margin: 0 -8px;
  justify-content: space-around;

  svg {
    width: 1.5rem;
    height: 1.5rem;
  }
`;
